<template>
  <div>
    <v-app class="bodyHomeYellow">
      <v-toolbar
        class="failed__header"
        elevation="0"
        style="background: #FFF000"
      >
        <v-flex class="step__header-main-image">
          <v-img
            class="failed__main-logo"
            src="@/assets/logo.svg"
            alt="logo"
            position="center center"
            @click="$router.replace({ name: 'home' })"
          />
        </v-flex>
        <v-flex>
          <h1 class="text-right textHeaderDesktop">
            Yo te presto.
          </h1>
        </v-flex>
      </v-toolbar>

      <!--      <v-main-->
      <!--        class="bodyHomeYellow"-->
      <!--        style="margin: 3% 30px 30px 40px"-->
      <!--      >-->
      <v-main class="step__main-body">
        <div class="templateDesktop">
          <v-container
            fluid
            class="failed__title"
          >
            <v-row class="align-center align-content-center">
              <v-col
                class="col-sm-12 col-md-6 col-12 text-center"
                align-self="center"
              >
                <v-card
                  class="templateFailDesktop failed__template--header"
                  style="background-color:#9C9C9C;"
                >
                  <v-col
                    align="center"
                    justify="center"
                  >
                    <h2
                      class="whiteText"
                      style="margin-top:30px;"
                    >
                      ¡Lo sentimos!
                    </h2>
                    <v-img
                      id="imagePetSad"
                      src="@/assets/img/pet_sad.svg"
                      alt="Mascota saludando"
                      max-height="80%"
                    />
                  </v-col>
                </v-card>
              </v-col>
              <v-col
                class="col-md-6 align-self-center col-sm-12 failed__txt"
                align="center"
                justify="center"
              >
                <h4>En este momento no contamos con una oferta para vos.</h4>
                <h4 class="failed__subtext">
                  Volvé a consultar en 3 meses.
                </h4>
                <v-btn
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnAmarillo"
                  @click="next()"
                >
                  Cerrar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { homeRoute } from "@/helper/homeRoute";

export default {
  name: "ErrorStep",
  mounted() {
    this.$gtm.trackEvent({
      noninteraction: false,
      event: "WEB_ARG_CREDIT_REJECTED",
      id_person: this.$store.getters.getUserForm,
    });
  },
  methods: {
    ...mapActions(["resetStore"]),
    next() {
      this.resetStore();
      this.$router.replace({ name: homeRoute.HOME });
    },
  },
};
</script>
