import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Person from '../components/model/Person'
import SecureLS from "secure-ls";
import {steps} from "@/helper/steps";

const ls = new SecureLS({ isCompression: false });
Vue.use(Vuex)
const phone = process.env.VUE_APP_PHONE_CUSTOMER_SUPPORT;
let stepsInfo = {};
stepsInfo[steps.MAIN_DOCUMENTATION] = {
  dni : true,
  selfie: true,
};

const initialState =() => { return {
  auth: {
    isAuthenticated: false
  },
  goSignup: false,
  loader: false,
  loaderMsg: "Consultando datos...",
  errorModal: false,
  errorModalMsg: "",
  warning: {
    modal: false,
    message: "",
    btnTxtHome: "Salir",
    btnTxtStay: "Continuar"
  },
  information: {
    modal: false,
    message: "",
    action: null
  },
  stepperStyle:"solicitud__stepper-img--margin",
  stepsManage: stepsInfo,
  session: {
    user: null,
    date: null,
    person: null,
    loan: {
      amount: null,
      installment: null,
      avgInstallment: null,
      loanId: null
    },
    card: null,
    oldCustomer: null,
    device: null,
    bank: null,
    pictures:{
      front: null,
      back: null,
      selfie: null
    },
    location:{
      latitude: null,
      longitude: null
    },
    email: null,
    availableUser: null
  },
  redirectToWhastapp() {
    window.open("https://wa.me/"+phone);
  }
}};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }})
  ],
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_USER(state, form_user) {
      state.session.person = new Person(form_user.fullname?form_user.fullname:null,
                                        form_user.dni?form_user.dni:null,
                                        form_user.cuit?form_user.cuit:null,
                                        form_user.phone?form_user.phone:null,
                                        form_user.name?form_user.name:null,
                                        form_user.birthday?form_user.birthday:null,
                                        form_user.lastname?form_user.lastname:null);
    },
    SET_CBU_BANK(state, bank) {
      state.session.bank = bank
    },
    SET_LOADER(state, loader) {
      state.loader = loader
    },
    SET_LOADER_MSG(state, msg) {
      state.loaderMsg = msg
    },
    SET_DEVICE(state) {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    state.session.device = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
    },
    SET_LOAN(state, form_user) {
      state.session.loan.amount = form_user.amount;
      state.session.loan.installment = form_user.installment;
      state.session.loan.avgInstallment = form_user.avgInstallment;
      state.session.loan.loanId = form_user.loanId;
    },
    SET_PICTURES(state, form_picture) {
      state.session.pictures.back = form_picture.photo_back;
      state.session.pictures.front = form_picture.photo_front;
      state.session.pictures.selfie = form_picture.photo_selfie;
    },
    RESET_STORE(state) {
      Object.assign(state, initialState())
    },
    SET_LOCATION(state, form_location) {
      state.session.location.latitude = form_location.latitude;
      state.session.location.longitude = form_location.longitude;
    },
    SET_EMAIL(state, email) {
      state.session.email = email;
    },
    ERROR_MODAL(state, errorModal) {
      state.errorModal = errorModal
    },
    SET_ERROR_MODAL_MSG(state, msg) {
      state.errorModalMsg = msg
    },
    SET_USER_FORM(state, msg) {
      state.session.user = msg
    },
    LOG_IN(state) {
      state.auth.isAuthenticated = true;
    },
    WARNING_MODAL(state, goOpen) {
      state.warning.modal = goOpen;
    },
    SET_WARNING_MSG(state, msg) {
      state.warning.message = msg
    },
    SET_WARNING_btn_txt_home(state, msg) {
      state.warning.btnTxtHome = msg
    },
    SET_WARNING_btn_txt_stay(state, msg) {
      console.log(msg)
      state.warning.btnTxtStay = msg
    },
    SET_INFORMATION(state, argInformation) {
      state.information.modal = argInformation.hasOwnProperty("modal")?argInformation.modal:true;
      state.information.message = argInformation.message;
      state.information.action = argInformation.action;
    },
    SET_AUTH_MAIN_DOCUMENTATION(state, form) {
      state.stepsManage[steps.MAIN_DOCUMENTATION].dni = form.dniPhoto;
      state.stepsManage[steps.MAIN_DOCUMENTATION].selfie = form.selfie;
    },
    SET_GO_LOGIN(state, val) {
      state.goLogin = val;
    },
    SET_GO_SIGNUP(state, val) {
      state.goSignup = val;
    },
    SET_SESSION_CUSTOMER(state, val) {
      state.session.oldCustomer = val;
    },
    SET_AVAILABLE_USER(state, msg) {
      console.log(msg);
      state.session.availableUser = msg;
    },
    SET_STYLE_STEPPER(state, val) {
      state.stepperStyle = val;
    }
  },
  actions: {
    logIn({ commit }) {
      commit('LOG_IN');
    },
    logout({ commit }) {
      commit('SET_USER', {});
      commit('SET_AUTHENTICATED', false);
    },
    setClient({ commit }, form) {
      commit('SET_USER', form);
    },
    setBank({ commit }, form) {
      commit('SET_CBU_BANK', form);
    },
    openLoader({ commit },msg) {
      commit('SET_LOADER', true);
      if(msg)
      commit('SET_LOADER_MSG', msg);
    },
    closeLoader({ commit }) {
      commit('SET_LOADER', false);
      commit('SET_LOADER_MSG', "Consultando datos...");
    },
    resetStore({ commit }) {
      commit('RESET_STORE');
    },
    setEmail({ commit }, email) {
      commit('SET_EMAIL', email);
    },
    goWarningModal({commit}, goOpen) {
      commit('WARNING_MODAL', goOpen);
    },
    setWarningMessageModal({ commit }, msg) {
      commit('SET_WARNING_MSG', msg);
    },
    setTxtBtnWarning({commit}, btnTxtHome, btnTxtStay) {
      commit('SET_WARNING_btn_txt_home', btnTxtHome);
    },
    setTxtBtnWarningStay({commit}, btnTxtStay) {
      commit('SET_WARNING_btn_txt_stay', btnTxtStay);
    },
    openInformation({commit}, argInformation) {
      commit('SET_INFORMATION', argInformation);
    },
    closeInformation({commit}) {
      let argInformation = {modal:false, message:"", action:""};
      commit('SET_INFORMATION', argInformation);
    },
    setLoan({commit}, formLoan) {
      commit('SET_LOAN', formLoan);
    },
    setMainData({commit}, form) {
      commit('SET_AUTH_MAIN_DOCUMENTATION', form);
    },
    setGoSignUp({commit}, val) {
      commit('SET_GO_SIGNUP', val);
    },
    setSessionOldCustomer({commit}, val) {
      commit('SET_SESSION_CUSTOMER', val);
    },
    isAvailableUser({commit}, res) {
      commit('SET_AVAILABLE_USER', res);
    },
    setStyleStepper({commit}, val) {
      commit('SET_STYLE_STEPPER', val);
    }
  },
  getters: {
    getFullname(state) {
      let fullname = "no definido";
      if(state.session.person){
        fullname = state.session.person._fullname;
      }
      return fullname;
    },
    getPhone(state) {
      let phone = "no definido";
      if(state.session.oldCustomer){
        return null;
      }
      if(state.session.person){
        phone = state.session.person._phone;
      }
      return phone;
    },
    getBank(state) {
      return state.session.bank;
    },
    getLoader(state) {
      return state.loader;
    },
    getIdentity(state) {
      let phone = "no definido";
      if(state.session.person){
      }
      return phone;
    },
    getLoanAmount(state) {
      return state.session.loan.amount;
    },
    getLoanInstallment(state) {
      return state.session.loan.installment;
    },
    getPictures(state){
      return state.session.pictures;
    },
    getLoanAvgInstallment(state) {
      return state.session.loan.avgInstallment;
    },
    getLoan(state){
      return state.session.loan.loanId;
    },
    getLoaderMsg(state) {
      return state.loaderMsg;
    },
    getEmail(state) {
      return state.session.email;
    },
    getErrorModal(state) {
      return state.errorModal;
    },
    getUserForm(state) {
      return state.session.user;
    },
    getErrorMessage(state) {
      return state.errorModalMsg;
    },
    auth: (state) => state.auth,
    getWarning: (state) => state.warning.modal,
    getWarningMessage: (state) => state.warning.message,
    getBtnTxtHome: (state) => state.warning.btnTxtHome,
    getBtnTxtStay: (state) => state.warning.btnTxtStay,
    getInformationMessage: (state) => state.information.message,
    getInformationAction: (state) => state.information.action,
    getInformation: (state) => state.information.modal,
    getNeedStepSelfie: (state) => state.stepsManage[steps.MAIN_DOCUMENTATION].selfie,
    getNeedStepDni: (state) => state.stepsManage[steps.MAIN_DOCUMENTATION].dni,
    goToPhotosStep(state) {
      return state.stepsManage[steps.MAIN_DOCUMENTATION].selfie ||
          state.stepsManage[steps.MAIN_DOCUMENTATION].dni
    },
    getCardStepDocumentation(state){
      if(state.stepsManage[steps.MAIN_DOCUMENTATION].dni) {
        return 1;
      }
      if(state.stepsManage[steps.MAIN_DOCUMENTATION].selfie) {
        return 5;
      }
      return 7;
    },
    getName(state) {
      let name = "no definido";
      if(state.session.person){
        name = state.session.person._name;
      }
      return name;
    },
    getGoSignup: (state) => state.goSignup,
    getPreffix: (state) => state.session.oldCustomer?'WEB_ARG_RENOVADOR':'WEB_ARG',
    getAvailable: (state) => {
      if(state.session.oldCustomer){return 1;}
      return  state.session.availableUser;
    },
    getStepperStyle: state => state.stepperStyle,
    getIsDevice: (state) => state.session.device,
    getDataUser: (state) => state.session.person
  }
})
