<template>
  <div>
    <div v-if="cantNewCredit">
      <v-row align="center" justify="center">
        <div class="col-md-4 col-xs-12" align="center" justify="center">
          <v-img
            src="@/assets/img/pet_sad.svg"
            alt="Mascota"
            position="center center"
          />
        </div>

        <div class="col-md-8 col-xs-12" align="center" justify="center">
          <h1 class="stepLoan--textImg">¡Lo sentimos!</h1>
          <div>En este momento <b>no contamos con una oferta para vos.</b></div>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { userRoute } from "@/helper/userRoute";
export default {
  name: "NewCredit",
  data: () => {
    return {
      cantNewCredit: false,
    };
  },
  mounted() {
    this.verifyNewCredit();
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader"]),
    verifyNewCredit() {
      this.openLoader("Estamos buscando la mejor oferta para vos…");
      this.$apiserver
        .getNewCredit()
        .then((data) => {
          if (data.aptCredit) {
            this.$router.push({ name: userRoute.NEW_LOAN });
            this.closeLoader();
          } else {
            this.cantNewCredit = true;
            this.closeLoader();
            this.$gtm.trackEvent({
              noninteraction: false,
              event: `WEB_ARG_RENOVADOR_CREDIT_REJECTED`,
              id_person: this.$store.getters.getUserForm,
            });
          }
        })
        .catch((error) => {
          this.closeLoader();
          console.log(error);
          this.$store.commit(
            "SET_ERROR_MODAL_MSG",
            "¡Ups! Tuvimos un inconveniente. Por favor, inténtalo más tarde."
          );
          this.$store.commit("ERROR_MODAL", true);
        });
    },
  },
};
</script>