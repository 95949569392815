export const apiRoute = Object.freeze({
    BCRA: "api/presterAr/bcraData",
    LOGIN: "api/login",
    SEND_SMS: "api/phone/sendsms",
    VALIDATE_PHONE: "api/phone/validate",
    SINGUP: "api/people/singup",
    VALIDATE: "api/presterAr/validate",
    CREDIT_CONFIG: "api/personalCredit/getConfig",
    IMAGE: "api/people/image",
    CREATE_FORM: "api/formData/create",
    CREDIT_REQUIRE: "api/personalCredit/require",
    FINISH_CREDIT: "api/personalCredit/finishDataGathering",
    PAYMENT_REGISTRATION:"api/payment/requestPaymentRegistrationEndpoint",
    SET_DATA: "api/people/setMyData",
    SEND_QUERIES: "api/presterAr/sendQueries",
    ALREADY_REGISTERED: "api/personalCredit/alreadyRegistered",
    STATUS_LAST_LOAN: "api/personalCredit/myLastCredit",
    LIST_ALL_PERSONAL_CREDITS: "api/personalCredit/list",
    GET_CONDITIONS_CREDIT: "api/personalCredit/getCondition",
    GET_NEW_CREDIT: "api/personalCredit/myAccount",
    GET_USER_INFO: "api/formData/findByUserAndUserForm",
    GET_USER_DATA: "api/formData/findByUserAndUserForm"
});