<template>
  <div>
    <div>
      <h3 class="textAlignLeft">
        Contacto
      </h3>
      <v-row>
        <v-col class="contactUs__row--margin">
          <v-row>
            <v-col>
              <v-btn
                color="#00E482"
                block
                rounded
                dark
                height="50px"
                @click="$store.state.redirectToWhastapp()"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-whatsapp
                </v-icon>
                Whatsapp
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                color="#FFC946"
                block
                rounded
                dark
                height="50px"
                @click="redirectTo('mail')"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-email-outline
                </v-icon>
                Mail
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="$showSocialMedia()">
            <v-col>
              <v-btn
                color="#FD0050"
                block
                rounded
                dark
                height="50px"
                @click="redirectTo('instagram')"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-instagram
                </v-icon>
                Instagram
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="$showSocialMedia()">
            <v-col>
              <v-btn
                color="#4050AD"
                block
                rounded
                dark
                height="50px"
                @click="redirectTo('facebook')"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-facebook
                </v-icon>
                Facebook
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
        <v-col
          align="center"
          justify="center"
          class="my-data__col--vissibility"
        >
          <v-img
            class="imageCircleDesktopLoading"
            src="@/assets/img/image_circle.png"
            alt="logo"
          >
            <v-img
              class="imagePetPenDesktopLoading"
              src="@/assets/img/pet_glasses.png"
              alt="Mascota"
            />
          </v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ContactUs",
    data: () => {
      return {
      }
    },
    mounted() {
    },
    methods: {
      redirectTo(social) {
        let url = '';
        switch(social) {
          case 'facebook' :
            url = process.env.VUE_APP_PROFILE_FACEBOOK;
            break;
          case 'mail' :
            url = "mailto:consultas@prester.com.ar";
            break;
          case 'instagram' :
            url = process.env.VUE_APP_PROFILE_INSTAGRAM;
            break;
        }
        window.open(url);
      }
    }
  }
</script>