<template>
  <div>
    <v-dialog v-model="showDetail" max-width="503px" scrollable>
      <v-card class="cardDialog" style="height: 556px !important">
        <v-card-title class="title-card-detailLoan">
          <h4 class="title-font-card-detailLoan">Detalle del préstamo</h4>

          <div class="icon-close-detailLoan">
            <v-icon color="#000000" title="cerrar" large @click="close()">
              mdi-close
            </v-icon>
          </div>
        </v-card-title>

        <v-container class="detail-loan-dialog__main-box">
          <v-row
            dense
            class="
              detail-loan-dialog__rows--border
              detail-loan-dialog__subtitle--border-row
            "
          >
            <v-col class="detail-loan-dialog__subtitle--border-col">
              <v-subheader class="text-h6 font-weight-bold">
                Solicitud n° {{ loan.loan }}
              </v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="font-weight-bold"> Fecha Alta </v-subheader>
            </v-col>
            <v-col>
              <v-subheader>{{ loan.dateActive }}</v-subheader>
            </v-col>
            <v-col>
              <v-subheader class="font-weight-bold"> Estado </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> {{ loan.status }} </v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="text-h6 font-weight-bold">
                Cuotas
              </v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="font-weight-bold"> Pagas </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> {{ loan.installments_paid }}</v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="font-weight-bold"> Atrasadas </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> {{ loan.installments_delayed }}</v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="font-weight-bold"> A vencer </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> {{ loan.installments_to_pay }}</v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="font-weight-bold"> Monto cuota </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> ${{ loan.installment_amount }}</v-subheader>
            </v-col>
          </v-row>
          <v-row dense class="detail-loan-dialog__rows--border">
            <v-col>
              <v-subheader class="font-weight-bold"> Último pago </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> {{ loan.last_payment }}</v-subheader>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-subheader class="font-weight-bold">
                Próximo vencimiento
              </v-subheader>
            </v-col>
            <v-col>
              <v-subheader> {{ loan.next_expiry_date }}</v-subheader>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DetailLoanDialog",
  props: {
    loan: { type: Object, default: null },
    value: { type: Boolean, default: false },
  },
  computed: {
    showDetail: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    close() {
      this.showDetail = false;
    },
  },
};
</script>
<style>
.container {
  padding: 0px !important;
}

.v-card__title {
  display: block !important;
}

.v-subheader {
  color: black !important;
}

.v-card__actions {
  padding: 0px 16px !important;
}
