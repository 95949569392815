import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/NotFound'
import store from '@/store'
import api from "@/router/api";
import home from "@/router/home";
import loanSteps from "@/router/loanSteps";
import user from "@/router/user";

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})


let msgBackButton = "Si sale de la página actual, se perderá la solicitud. ¿Continuar con la solicitud?";

Vue.use(VueRouter)

const initialRoutes = [
  {
    path: 'http://play.google.com/store/apps/details?id=ar.com.findosiftsa',
    name: 'playstore'
  },
  {
    path: '*',
    beforeEnter (to, from, next) {
      window.location = process.env.VUE_APP_URL + "/404"
    }
  },
  {
    path: "/404",
    component: NotFound
  }
]
const routes = initialRoutes.concat(
    home,
    loanSteps,
    api,
    user
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let backButton = window.popStateDetected;
  window.popStateDetected = false;
  let clientBack = to.name === "new_loan" && from.name === "request_client_information";
  let newClientBack = (from.name === "request_new_loan" && to.name === "request_login") ||
      (from.name === "request_new_loan" && to.name === "request_loan_mail");
  let exitLoan = clientBack || newClientBack;
  if( backButton && exitLoan) {
    store.commit('SET_WARNING_MSG', msgBackButton);
    store.commit('WARNING_MODAL', true);
    next(false);
    return '';
  }

  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    router,
    store
  }

  return middleware[0]({
    ...context})

})

export default router
