<template>
  <div>
    <v-app>
      <v-row class="row-header-gestion">
        <v-col>
          <v-app-bar-nav-icon
            class="icon-header-management"
            @click.stop="drawer = !drawer"
          />
        </v-col>
        <v-col
          align="center"
          justify="center"
        >
          <v-img
            class="image-logo-header-managment"
            src="@/assets/logo.svg"
            alt="logo"
          />
        </v-col>
        <v-col />
      </v-row>
      <v-row class="row-header-gestion">
        <v-col
          align="center"
          justify="center"
        >
          <h4 class="darkPurple text-management">
            ¡Hola, {{ clientName }}!
          </h4>
        </v-col>
      </v-row>
      <div class="body-home-yellow-gestion">
        <div class="headerRoundedWhite">
&nbsp;
        </div>
      </div>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        overflow-y="scroll"
        :permanent="$vuetify.breakpoint.mdAndUp"
        mobile-breakpoint="960"
        color="#FFF000"
        class="navigation-drawer-desktop"
      >
        <v-list class="navigation-drawer-top-managment">
          <v-list-item class="px-2">
            <v-img
              src="@/assets/img/pet_saying_hello.svg"
              class="image-profile-managment center-item"
            />
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 box-email">
                {{ mail }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense>
          <v-list-item link>
            <router-link
              :to="{ name: userRoute.STATUS_LOAN }"
              class="layout-manager-user__menu"
            >
              <v-list-item-icon>
                <v-img
                  src="@/assets/img/icon_status.svg"
                  class="icon-circle-size-managment"
                />
              </v-list-item-icon>
              <v-list-item-title>Estado de mi solicitud</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item link>
            <router-link
              :to="{ name: userRoute.MY_LOANS }"
              class="layout-manager-user__menu"
            >
              <v-list-item-icon>
                <v-img
                  src="@/assets/img/icon_loan.svg"
                  class="icon-circle-size-managment"
                />
              </v-list-item-icon>
              <v-list-item-title>Mis préstamos</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item link>
            <router-link
              :to="{ name: userRoute.NEW_CREDIT }"
              class="layout-manager-user__menu"
            >
              <v-list-item-icon>
                <v-img
                  src="@/assets/img/icon_request_new_loan.svg"
                  class="icon-circle-size-managment"
                />
              </v-list-item-icon>
              <v-list-item-title>Pedir nuevo préstamo</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item link>
            <router-link
              :to="{ name: userRoute.MY_DATA }"
              class="layout-manager-user__menu"
            >
              <v-list-item-icon>
                <v-img
                  src="@/assets/img/icon_data.svg"
                  class="icon-circle-size-managment"
                />
              </v-list-item-icon>
              <v-list-item-title>Mis datos</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item link>
            <router-link
              :to="{ name: userRoute.CONTACT_US }"
              class="layout-manager-user__menu"
            >
              <v-list-item-icon>
                <v-img
                  src="@/assets/img/icon_contact.svg"
                  class="icon-size-managment"
                />
              </v-list-item-icon>
              <v-list-item-title>Contacto</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item class="fixedBottom">
            <router-link
              :to="{ name: homeRoute.HOME }"
              class="layout-manager-user__menu"
              @click.native="exitAccount()"
            >
              <v-list-item-icon>
                <v-img
                  src="@/assets/img/icon_logout.svg"
                  class="icon-size-managment"
                />
              </v-list-item-icon>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </router-link>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main class="home-managment__body">
        <v-container class="container--scroll-overflow">
          <router-view />
        </v-container>
      </v-main>
      <loading-dialog />
      <v-dialog
        v-model="error"
        persistent
        width="350px"
      >
        <ErrorRequest />
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import ErrorRequest from "@/components/popUps/ErrorRequest";
import {
  mdiAccountDetails,
  mdiFolderMultiple,
  mdiBankPlus,
  mdiLogoutVariant,
  mdiAccountOutline,
  mdiContacts,
} from "@mdi/js";
import { userRoute } from "@/helper/userRoute";
import { homeRoute } from "@/helper/homeRoute";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ManagementLoan",
  components: {
    SvgIcon,
    ErrorRequest,
  },
  props: {},
  data: () => ({
    drawer: false,
    items: null,
    accountDetails: mdiAccountDetails,
    folderMultiple: mdiFolderMultiple,
    bankPlus: mdiBankPlus,
    logoutVariant: mdiLogoutVariant,
    accountOutline: mdiAccountOutline,
    contacts: mdiContacts,
    userRoute,
    homeRoute,
  }),
  computed: {
    clientName() {
      return this.$store.getters.getName;
    },
    mail() {
      return this.$store.getters.getEmail;
    },
    error() {
      return this.$store.getters.getErrorModal;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    if(this.getFullname() !== "no definido"){
      return;
    }
    this.$apiserver.getInfoUser().then((res) => {
      this.setClient(res);
      this.setSessionOldCustomer(true);
    }).catch((error) => {
      console.log(error);
    });

    if (!!document.getElementById("material-css") == false) {
      let addCss = document.createElement("link");
      addCss.setAttribute("rel", "stylesheet");
      addCss.setAttribute("id", "material-css");
      addCss.setAttribute(
        "href",
        "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"
      );
      document.head.appendChild(addCss);

      let addCss2 = document.createElement("link");
      document.head.appendChild(addCss2);
      addCss2.setAttribute("rel", "stylesheet");
      addCss2.setAttribute("href", "/material/scss/material-dashboard.scss");

      document.head.appendChild(addCss2);

      let addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "https://res.mobbex.com/js/embed/mobbex.embed@1.0.20.js"
      );
      addScript.setAttribute("crossorigin", "anonymous");
      document.head.appendChild(addScript);
    }
  },
  methods: {
    ...mapActions(["setClient", "resetStore", "setSessionOldCustomer"]),
    ...mapGetters(["getFullname"]),
    exitAccount() {
      this.resetStore();
    },
  },
};
</script>
<style>
  html.overflow-y-hidden {
    overflow-y: scroll !important;
  }
</style>