<template>
  <div>
    <v-app>
      <div class="home-space--background">
        <div>&nbsp;</div>
      </div>
      <v-toolbar class="headerTopHome" elevation="0">
        <v-app-bar-nav-icon
          class="iconHeaderHome"
          @click.stop="drawer = !drawer"
        />
        <v-col align="center" justify="center">
          <v-img class="home__main-logo" src="@/assets/logo.svg" alt="logo" />
        </v-col>
        <v-tabs
          class="menuDesktop"
          right
          background-color="#FDEE01"
          color="#000000"
        >
          <v-tab @click.stop="toSection('PorQuePrester')"> Beneficios </v-tab>
          <!--<v-tab @click.stop="toSection('ComoCredito')"> Cómo funciona </v-tab>-->
          <v-tab v-show="showOpinions" @click.stop="toSection('Opiniones')">
            Comunidad
          </v-tab>
          <v-tab @click.stop="toSection('Ayudar')"> Ayuda </v-tab>
          <v-tab @click.stop="toSection('PreguntasFrecuentes')">
            Preguntas frecuentes
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-navigation-drawer v-model="drawer" absolute temporary color="#FFF000">
        <v-list dense>
          <v-list-item v-for="item in items" :key="item.title" link>
            <v-list-item-content>
              <v-list-item-title>
                <a
                  style="color: black"
                  @click.stop="toSectionNavDrawer(item.id)"
                >
                  {{ item.title }}
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <div class="home-first-section">
          <div class="header--background">
            <v-row class="home-first-section__header">
              <v-col align="left" md="12">
                <h2 class="home-first-section__header-title">
                  Te presto más rápido y 100% online
                </h2>
              </v-col>
            </v-row>
          </div>
          <v-row class="home-first-section__body">
            <v-col class="mainLogoDesktop" align="center" md="7" sm="12">
              <v-img
                id="imagePetCreditDesktop"
                class="text-left"
                src="@/assets/img/prestercito_landing-01.gif"
                alt="logo"
                style="margin-top: 80px"
              />
            </v-col>

            <v-col align="center" justify="center">
              <v-sheet
                class="panel__slider roundedSheetHome paddingSheetHome"
                width="70%"
                max-width="100%"
              >
                <div>
                  <h4 class="panel__slider-title textTitleNonBold">
                    ¿Cuánto queres pedir?
                  </h4>
                  <h3>${{ Number(formDue.amount).toLocaleString("es-AR") }}</h3>
                  <v-slider
                    v-model="formDue.amount"
                    step="1000"
                    :min="minAmount"
                    :max="maxAmount"
                    :color="formDue.color"
                    :track-color="formDue.trackColor"
                    height="4px"
                  />
                  <v-row>
                    <v-col align="left" justify="left">
                      <h4 class="textBottomSliderLeft textTitleNonBold">
                        ${{
                          minAmount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                      </h4>
                    </v-col>
                    <v-col align="right" justify="right">
                      <h4 class="textBottomSliderRightAmount textTitleNonBold">
                        ${{
                          maxAmount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                      </h4>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <h4 class="panel__slider-amount textTitleNonBold">
                    ¿En cuántos meses lo querés pagar?
                  </h4>
                  <h3>
                    {{ formDue.installment }}
                  </h3>
                  <v-slider
                    v-model="formDue.installment"
                    step="3"
                    :min="minInstallment"
                    :max="maxInstallment"
                    :color="formDue.color"
                    :track-color="formDue.trackColor"
                    height="4px"
                  />
                  <v-row>
                    <v-col align="left" justify="left">
                      <h4 class="textBottomSliderLeft textTitleNonBold">
                        {{ minInstallment }}
                      </h4>
                    </v-col>
                    <v-col align="right" justify="right">
                      <h4
                        class="textBottomSliderRightQuotation textTitleNonBold"
                      >
                        {{ maxInstallment }}
                      </h4>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col align="center" justify="center">
                    <v-btn
                      bottom
                      depressed
                      rounded
                      class="btnAmarillo divHomeTopMargin"
                      @click="startLoan()"
                    >
                      Continuar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="homeUserFeature">
                  <v-col align="center" justify="center">
                    <v-btn
                      bottom
                      depressed
                      rounded
                      class="btnTransparente"
                      @click="loginUser"
                    >
                      Ya soy cliente
                    </v-btn>
                  </v-col>
                </v-row>
                <br />
              </v-sheet>
            </v-col>
          </v-row>
        </div>

        <div id="PorQuePrester" class="bodyHomeGrey">
          <v-row class="sectionWhyPrester">
            <v-col align="center" justify="center">
              <h3 class="boldMountedText">¿Por qué Prester?</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="home__why-prester--margin"
              align="center"
              justify="center"
            >
              <v-img
                id="imagePetRunning"
                src="@/assets/img/pet_running.png"
                alt="Mascota corriendo"
              />
              <p class="textHome">100% Digital</p>
              <p>Pedime en 5 minutos y te deposito hoy.</p>
            </v-col>
            <v-col
              align="center"
              justify="center"
              class="home__why-prester--margin"
            >
              <v-img
                id="imagePetConfidential"
                src="@/assets/img/pet_confidential.png"
                alt="Mascota confiable"
              />
              <p class="textHome">Soy confiable</p>
              <p>Soy una entidad registrada en el BCRA.</p>
            </v-col>
            <v-col
              align="center"
              justify="center"
              class="home__why-prester--margin"
            >
              <v-img
                id="imagePetOk2"
                src="@/assets/img/pet_ok.png"
                alt="Mascota ok"
                class="home__why-prester-icon--margin"
              />
              <p class="textHome">Sin sorpresas</p>
              <p>Soy transparente, no hay letra chica.</p>
            </v-col>
            <v-col
              align="center"
              justify="center"
              class="home__why-prester--margin"
            >
              <v-img
                id="imagePetWinning"
                src="@/assets/img/pet_winning.png"
                alt="Mascota con trofeo"
              />
              <p class="textHome">Premio tu esfuerzo</p>
              <p class="divHomeBottomMargin">
                Confío en vos. Si pagás a tiempo te doy un descuento para el
                próximo préstamo.
              </p>
            </v-col>
          </v-row>
        </div>

        <!--<div id="ComoCredito" class="home__section--background">
          <v-row class="">
            <v-col align="center" justify="center" class="home__section-video">
              <h3 class="boldMountedText">¿Cómo me pedís un préstamo?</h3>
              <section class="divHomeBottomMargin">
                <iframe
                  class="home__video--size"
                  src="https://www.youtube.com/embed/qXQUgi2FfS8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </section>
            </v-col>
          </v-row>
        </div>
        -->

        <div class="bodyHomeGrey">
          <v-row class="divHomeMarginSides">
            <v-col align="right" justify="right" class="col-md-1 offset-md-3">
              <v-img
                id="imageIconSolicitud"
                src="@/assets/img/icon_solicitud.png"
                alt="Solicitud"
              />
            </v-col>
            <v-col
              align="left"
              justify="left"
              class="col-md-2 home__why-prester--margin"
              style="margin-top: 50px"
            >
              <p class="textHome textAlignLeft">Completá la solicitud</p>
              <p class="textAlignLeft">
                Tené a mano tu DNI, Tarjeta de Débito y peinate para la selfie.
              </p>
            </v-col>
            <v-col align="right" justify="right" class="col-md-1">
              <v-img
                id="imageIconDisfruta"
                src="@/assets/img/icon_disfruta.png"
                alt="Disfruta"
              />
            </v-col>
            <v-col
              align="left"
              justify="left"
              style="margin-top: 50px"
              class="col-md-2"
            >
              <p class="textHome textAlignLeft">Disfrutá</p>
              <p class="divHomeBottomMargin textAlignLeft">
                Recibilo en 1 día hábil en tu cuenta.
              </p>
            </v-col>
          </v-row>
        </div>

        <div v-if="showOpinions" id="Opiniones" class="bodyHomeYellow">
          <v-row class="divHomeMarginSides">
            <v-col align="center" justify="center">
              <h3 class="boldMountedText">
                Mirá lo que dicen los que me conocen.
              </h3>
              <v-row>
                <v-col>
                  <v-img
                    id="imageClientHome"
                    class="divHomeTopMargin"
                    src="@/assets/img/photo_client.png"
                    alt="Cliente"
                  />
                  <v-img
                    id="imageStarsHome"
                    class=""
                    src="@/assets/img/icon_star_group.png"
                    alt="Estrellas"
                  />
                  <v-row>
                    <v-col>
                      <v-img
                        class="imageComillasIzqHomeDesktop"
                        src="@/assets/img/quotation_mark_first.png"
                        alt="Estrellas"
                      />
                    </v-col>
                    <v-col
                      class="textHomeOpinions"
                      align="center"
                      justify="center"
                    >
                      <p class="textHomeDesktop">
                        ¡Fácil, rápido y eficiente! Me salvaron, ¡gracias!
                      </p>
                      <p class="divHomeTopMargin">Ariadna Martinez</p>
                      <v-divider />
                    </v-col>
                    <v-col>
                      <v-img
                        class="imageComillasDerHomeDesktop"
                        src="@/assets/img/quotation_mark_second.png"
                        alt="Estrellas"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-img
                    id="imageClientHome"
                    class="divHomeTopMargin"
                    src="@/assets/img/photo_client.png"
                    alt="Cliente"
                  />
                  <v-img
                    id="imageStarsHome"
                    class=""
                    src="@/assets/img/icon_star_group.png"
                    alt="Estrellas"
                  />
                  <v-row>
                    <v-col>
                      <v-img
                        class="imageComillasIzqHomeDesktop"
                        src="@/assets/img/quotation_mark_first.png"
                        alt="Estrellas"
                      />
                    </v-col>
                    <v-col
                      class="textHomeOpinions"
                      align="center"
                      justify="center"
                    >
                      <p class="textHomeDesktop">
                        Excelente atención y rapidez.
                      </p>
                      <p class="divHomeTopMargin">Edith Florenciañez</p>
                      <v-divider />
                    </v-col>
                    <v-col>
                      <v-img
                        class="imageComillasDerHomeDesktop"
                        src="@/assets/img/quotation_mark_second.png"
                        alt="Estrellas"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-img
                    id="imageClientHome"
                    class="divHomeTopMargin"
                    src="@/assets/img/photo_client.png"
                    alt="Cliente"
                  />
                  <v-img
                    id="imageStarsHome"
                    class=""
                    src="@/assets/img/icon_star_group.png"
                    alt="Estrellas"
                  />
                  <v-row>
                    <v-col>
                      <v-img
                        class="imageComillasIzqHomeDesktop"
                        src="@/assets/img/quotation_mark_first.png"
                        alt="Estrellas"
                      />
                    </v-col>
                    <v-col
                      class="textHomeOpinions textMargin"
                      align="center"
                      justify="center"
                    >
                      <p class="textHomeDesktop">
                        Excelente atención y rapidez.
                      </p>
                      <p class="divHomeTopMargin">Edith Florenciañez</p>
                      <v-divider />
                    </v-col>
                    <v-col>
                      <v-img
                        class="imageComillasDerHomeDesktop"
                        src="@/assets/img/quotation_mark_second.png"
                        alt="Estrellas"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-form
          id="Ayudar"
          ref="formHelp"
          v-model="valid_documentation"
          class="bodyHomeYellow"
        >
          <v-row class="divHomeMarginSides">
            <v-col align="center" justify="center">
              <div class="home__helper-title-margin">
                <h3 class="boldMountedText">¿Cómo te podemos ayudar?</h3>
              </div>
              <div class="home__helper-form--margin">
                <v-row class="home__helper-form-row">
                  <v-col
                    align="center"
                    justify="center"
                    class="home__helper-input"
                  >
                    <v-text-field
                      v-model="helpForm.name"
                      class=""
                      tabindex="1"
                      label="Nombre"
                      color="black"
                      background-color="white"
                      :rules="[rules.required]"
                      filled
                      rounded
                      dense
                      required
                    />
                  </v-col>
                  <v-col
                    align="center"
                    justify="center"
                    class="home__helper-input"
                  >
                    <v-text-field
                      v-model="helpForm.lastName"
                      label="Apellido"
                      tabindex="2"
                      color="black"
                      background-color="white"
                      :rules="[rules.required]"
                      filled
                      rounded
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    align="center"
                    justify="center"
                    class="home__helper-input"
                  >
                    <v-text-field
                      v-model="helpForm.email"
                      label="Email"
                      tabindex="3"
                      color="black"
                      background-color="white"
                      :rules="[rules.required, rules.validateMail]"
                      filled
                      rounded
                      dense
                    />
                  </v-col>
                  <v-col
                    align="center"
                    justify="center"
                    class="home__helper-input"
                  >
                    <v-text-field
                      v-model="helpForm.phone"
                      label="Teléfono"
                      tabindex="4"
                      color="black"
                      background-color="white"
                      :rules="[rules.counterCel, rules.required]"
                      filled
                      rounded
                      dense
                    />
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="helpForm.comments"
                  label="Comentarios"
                  tabindex="5"
                  color="black"
                  background-color="white"
                  :rules="[rules.required]"
                  filled
                  rounded
                  dense
                />
              </div>
              <v-row>
                <v-col
                  class="home__questions-btn--margin"
                  align="center"
                  justify="center"
                >
                  <v-btn
                    bottom
                    depressed
                    rounded
                    class="btnNegro"
                    :disabled="!valid_documentation"
                    @click="sendEmailForHelp()"
                  >
                    Enviá tu consulta
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>

        <div id="PreguntasFrecuentes" class="bodyHomeGrey">
          <v-row class="home__questions">
            <v-col align="center" justify="center">
              <h3 class="boldMountedText">Preguntas frecuentes</h3>
              <div class="home__questions--margin">
                <v-expansion-panels v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Qué es Prester?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Prester es una fintech que promueve la inclusión
                      financiera en la Argentina. Ofrece préstamos inclusivos y
                      100% digitales a través del celular. Prester responde a la
                      necesidad de millones de argentinos que buscan acceder a
                      servicios financieros de calidad de manera ágil, segura y
                      desde donde quiera que estén. La tecnología de Prester
                      llegó para ampliar el acceso al crédito brindando una
                      solución a medida, en menos de 24hs. Prester es una marca
                      que pertenece a SIFT S.A. registrada en el BCRA.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      Promoción: Tu última cuota es GRATIS
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      ¡Buenas noticias! Si pedís un préstamo a través de la web
                      o App de Prester y te lo aprobamos antes del 16 de julio,
                      la última cuota no la pagás. A continuación te informamos
                      a detalle para que disfrutes la promo. Si tenés dudas,
                      contactanos por WhatsApp para que te podamos ayudar.
                      <br />
                      <b>Pasos para acceder a la promoción:</b>
                      <ol type="a">
                        <li>Ingresar a la página web o app de Prester</li>
                        <li>Completar una solicitud de préstamo online</li>
                        <li>
                          Cumplir con los requisitos de validación de identidad
                          y verificación crediticia
                        </li>
                        <li>
                          Cumplir con los criterios de la promoción detallados a
                          continuación
                        </li>
                        <li>
                          Aceptar los términos y condiciones del préstamo para
                          que Prester realice el desembolso
                        </li>
                        <li>
                          Pagar las cuotas en tiempo y forma según lo estipulado
                          en el contrato de adhesión
                        </li>
                        <li>
                          Prester realizará el reintegro de la última cuota en
                          la cuenta bancaria registrada a nombre del titular del
                          préstamo dentro de los 30 días del vencimiento de la
                          última cuota.
                        </li>
                      </ol>
                      <b
                        >Términos y condiciones generales de la promoción “Tu
                        última cuota GRATIS”</b
                      >
                      <br />
                      Los presentes son los Términos y Condiciones bajo los
                      cuales se desarrollará y realizará la actividad
                      promocional “Tu última cuota GRATIS” (en adelante “la
                      Promoción”). La persona que desee participar (en adelante
                      “El Participante”) reconoce y acepta que el desarrollo y
                      realización de esta actividad promocional se sujetará
                      única y exclusivamente a los mismos.
                      <ol>
                        <li>Objetivo de la promoción:</li>
                        El reintegro de la última cuota del Préstamo.
                        <br />
                        Se obtendrá cuando el cliente cancele el préstamo en su
                        totalidad.
                        <li>Vigencia de la promoción:</li>
                        La presente promoción aplica para los préstamos
                        realizados únicamente durante las 00:00:00 horas del 16
                        de mayo del 2022 y finaliza a las 23:59:00 horas del 16
                        de julio de 2022 a través de la página web
                        <a href="#">www.prester.com.ar</a
                        >.
                        <li>Requisitos para participar:</li>
                        Podrán participar aquellas personas que cumplan con las
                        políticas de riesgo de SIFT S.A y cumplan con los
                        siguientes parámetros:
                        <ol type="i">
                          <li>Primer préstamo con SIFT S.A.</li>
                          <li>
                            Préstamos a partir de $30.000 con un mínimo de 12
                            cuotas;
                          </li>
                          <li>
                            Realicen el pedido a través de la página web
                            <a href="#">www.prester.com.ar</a> o App de Prester.
                          </li>
                          <li>Paguen las cuotas en tiempo y forma.</li>
                          <li>Acepten los presentes Términos y Condiciones.</li>
                        </ol>
                        <li>Condiciones de la promoción:</li>
                        <ul type="disc">
                          <li>
                            No aplica para clientes existentes de SIFT S.A.
                          </li>
                          <li>
                            Promoción válida hasta alcanzar $2.200.000 en
                            préstamos.
                          </li>
                          <li>
                            SIFT S.A se reserva el derecho de cancelar pedidos
                            que sean considerados fraudulentos.
                          </li>
                          <li>
                            La campaña “Tu última cuota GRATIS” estará vigente
                            desde las 00:00:00 horas del 16 de mayo de 2022 y
                            finaliza a las 23:59:00 horas del 16 de julio de
                            2022.
                          </li>
                          <li>
                            Reintegro de la última cuota del préstamo dentro de
                            los 30 días del vencimiento de la misma.
                          </li>
                          <li>
                            La aceptación del Participante de la presente
                            promoción, implica el conocimiento y su
                            consentimiento expreso de los presentes Términos y
                            Condiciones.
                          </li>
                        </ul>
                        <li>Cobertura de la promoción:</li>
                        La Promoción aplica para los préstamos realizados en los
                        puntos de venta que se relacionan a continuación:
                        <ol type="i">
                          <li>
                            En la página web
                            <a href="#">www.prester.com.ar</a> o App de Prester
                          </li>
                          <li>
                            Territorio: El presente descuento aplica únicamente
                            al territorio argentino.
                          </li>
                        </ol>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Cuáles son los requisitos para solicitar un préstamo
                      online?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      <ol>
                        <li>Ser mayor de 21 años de edad.</li>
                        <li>Estar radicado en Argentina con DNI Argentino.</li>
                        <li>Tener un ingreso mensual demostrable.</li>
                        <li>
                          Ser titular de una cuenta bancaria, tener el CBU y la
                          tarjeta de débito a mano.
                        </li>
                        <li>
                          Completar la solicitud validando tu identidad con una
                          selfie y foto a tu DNI.
                        </li>
                        <li>
                          Por último, te vamos a pedir que tengas un teléfono
                          celular activo y un mail particular.
                        </li>
                        <li>
                          No tener antecedentes negativos en otras entidades
                          bancarias.
                        </li>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Qué necesito para pedir un préstamo online?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Necesitás tener conexión a internet, tu DNI y enviar tu
                      solicitud de préstamo.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Es difícil solicitar un préstamo?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      El proceso de solicitud es muy rápido y fácil. Completá la
                      solicitud con tus datos en 5 minutos, te sacás una selfie
                      y foto a tu DNI, evaluamos tu solicitud y te respondemos
                      en el transcurso del día.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Cuánto prestan? ¿Cuántas cuotas mensuales dan para
                      devolverlo?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      El monto mínimo es de $10.000 y el máximo es de $60.000.
                      Lo podés devolver en 3 a 18 meses, vos podes elegir.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Cuánto tardan en responderme?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      ¡Te respondemos en el día! Y si nos pediste un fin de
                      semana te respondemos el lunes.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿En cuanto tiempo lo acreditan?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      El monto aprobado se te acreditará dentro del plazo de
                      24hs hábiles.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Cómo me entero de que me aprobaron el préstamo online?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Te responderemos en el día a través del mail (correo
                      personal) que registraste en tu solicitud de préstamo. A
                      su vez, en el llamado de verificación se te va avisar de
                      su aprobación.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Cómo recibo el dinero de mi préstamo online?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Prester realiza una transferencia bancaria y deposita el
                      dinero directamente en una cuenta a tu nombre.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Qué tipo de contrato firmo?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Te compartimos el modelo de contrato que firmás con
                      nosotros para que tengas de referencia. "
                      <a
                        target="_blank"
                        href="/adhesioncontract"
                        style="color: black; text-decoration: underline"
                      >
                        Contratos de Adhesión - Ley Nro. 24.240 </a
                      >"
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      Si ya tengo un préstamo Prester: ¿puedo pedir otro?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Sí, para renovar o solicitar un segundo préstamo, debés
                      haber pagado el 50% (la mitad) del préstamo actual en
                      tiempo y forma. Te invitamos a contactarnos por nuestra
                      línea de atención al cliente, para recibir asistencia al
                      instante y mayor información.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Qué pasa si no aprueban la solicitud?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Si no tenemos un préstamo a tu medida, te lo vamos a
                      informar vía mail. Podés volver a intentar dentro de 3
                      meses.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Cómo pago las cuotas?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Las cuotas se descuentan de tu cuenta bancaria del 1 al 10
                      de cada mes.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Qué hacen con mis datos?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Tus datos están resguardados y sirven exclusivamente para
                      el análisis de la solicitud de préstamo. Prester trata con
                      confidencialidad los datos personales del usuario y la
                      información y adopta las medidas necesarias de seguridad
                      para proteger estos datos cumpliendo con el Decreto
                      Reglamentario Nº 1558/2001 de la Ley Nº 25.326, las
                      Disposiciones 011/2006, 9/2008 y 3/2012 de la DNPDP. El
                      usuario puede siempre ejercer sus derechos de acceso,
                      información, rectificación, actualización y supresión de
                      sus Datos Personales, conforme lo establecido en este
                      apartado y de acuerdo a la Ley de Protección de Datos
                      Personales No. 25.326 y normativa reglamentaria.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      Si tengo dudas sobre el crédito, ¿Con quien me contacto?
                      ¿Cómo lo hago?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Podés contactarnos por medio de nuestro WhatsApp al número
                      <a
                        target="_blank"
                        class="boldAndUnderlineText"
                        @click="$store.state.redirectToWhastapp()"
                        >{{ phoneWpp }}</a
                      >.
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header color="#FFF000">
                      ¿Qué pasa si me atraso?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="#FFFFFF"
                      class="textAlignLeft"
                    >
                      Te contactaremos para ofrecerte un plan de pagos para
                      ponerte al día y que no afecte tu historial crediticio.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="bodyHomeYellow">
          <v-row class="divHomeMarginSides">
            <v-col align="center" justify="center">
              <v-img
                id="imageLogoHome"
                class="divHomeTopMargin"
                src="@/assets/logo.svg"
                alt="Logo"
              />
              <p class="divHomeTopMargin">VISITANOS EN</p>
              <div
                v-if="$showSocialMedia()"
                id="redesSociales"
                class="redesSocialesDesktop"
              >
                <v-row>
                  <v-col>
                    <a :href="facebook" target="_blank">
                      <v-img
                        id="imageFacebookHome"
                        src="@/assets/img/icon_facebook.png"
                        alt="Facebook"
                      />
                    </a>
                  </v-col>
                  <v-col>
                    <a :href="instagram" target="_blank">
                      <v-img
                        id="imageInstagramHome"
                        src="@/assets/img/icon_instagram.png"
                        alt="Instagram"
                      />
                    </a>
                  </v-col>
                  <v-col>
                    <a target="_blank">
                      <v-img
                        id="imageWhatsappHome"
                        src="@/assets/img/icon_whatsapp.png"
                        alt="Whatsapp"
                        @click="$store.state.redirectToWhastapp()"
                      />
                    </a>
                  </v-col>
                </v-row>
              </div>
              <v-row class="divHomeBottomMargin">
                <v-col
                  v-if="!$showSocialMedia()"
                  :align="alignWpp"
                  :justify="justifyWpp"
                >
                  <a target="_blank">
                    <v-img
                      id="imageWhatsappHome"
                      src="@/assets/img/icon_whatsapp.png"
                      alt="Whatsapp"
                      @click="$store.state.redirectToWhastapp()"
                    />
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <div class="bodyHomeBlack">
          <div class="paddingFooterDesktop">
            <v-row>
              <v-col class="marginBotonesFooterDesktop home--cols-margin">
                <v-row>
                  <v-col align="center" justify="center">
                    <v-btn
                      bottom
                      depressed
                      rounded
                      block
                      class="btnAmarillo botonArrepentimientoDesktop"
                      href="mailto:reclamos@prester.com.ar"
                    >
                      Botón de arrepentimiento
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" justify="center">
                    <v-btn
                      bottom
                      depressed
                      rounded
                      block
                      class="btnAmarillo botonBajaDesktop"
                      href="mailto:reclamos@prester.com.ar"
                    >
                      Botón de baja
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="botonBajaDesktop home--cols-margin home__footer--margin"
              >
                <v-row>
                  <a
                    target="_blank"
                    href="/termsandconditions"
                    class="whiteAndUnderlineText"
                  >
                    Términos y Condiciones
                  </a>
                </v-row>
                <v-row>
                  <a
                    target="_blank"
                    href="/adhesioncontract"
                    class="whiteAndUnderlineText"
                  >
                    Contratos de Adhesión - Ley Nro. 24.240
                  </a>
                </v-row>
                <v-row>
                  <a
                    target="_blank"
                    href="/privatepolicy"
                    class="whiteAndUnderlineText"
                  >
                    Políticas de privacidad
                  </a>
                </v-row>
                <v-row>
                  <a
                    target="_blank"
                    href="/informationtofinancialusers"
                    class="whiteAndUnderlineText"
                  >
                    Información a usuarios financieros
                  </a>
                </v-row>
                <v-row>
                  <a
                    target="_blank"
                    href="/personalloans"
                    class="whiteAndUnderlineText"
                  >
                    Préstamos Personales: Tasas, comisiones y cargos
                  </a>
                </v-row>
                <v-row>
                  <a
                    target="_blank"
                    href="/consumerdefense"
                    class="whiteAndUnderlineText"
                  >
                    Defensa del consumidor
                  </a>
                </v-row>
              </v-col>
              <v-col
                class="home--cols-margin home__footer--padding"
                align="center"
                justify="center"
              >
                <v-row class="home__footer-info--padding">
                  <p class="divHomeTopMargin whiteText textAlignLeft">
                    Calle, 25 De Mayo 306 Piso 2 - CP 1002 <br />
                    CABA, Buenos Aires
                  </p>
                </v-row>
                <v-row class="home__footer-info--padding">
                  <p class="divHomeTopMargin whiteText textAlignLeft">
                    Prester es una empresa de tecnología y finanzas cuya razón
                    social es SIFT S.A. Está inscripta en el Registro de
                    Proveedores No Financieros de Crédito del Banco Central de
                    la República Argentina (BCRA) con Nº de Entidad 55.257.
                  </p>
                </v-row>
              </v-col>
              <v-col align="center" justify="center">
                <a
                  href="http://qr.afip.gob.ar/?qr=lwasZjzNS8GL3mAd03jNLA,,"
                  target="_F960AFIPInfo _blank"
                >
                  <v-img
                    id="imageDataFiscalHomeDesktop"
                    src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                  />
                </a>
                <a
                  href="http://www.bcra.gob.ar/BCRAyVos/Usuarios_Financieros.asp"
                  target="_blank"
                >
                  <v-img
                    id="imageBCRAHomeDesktop"
                    src="@/assets/img/image_bcra.png"
                    class="divHomeTopMargin"
                  />
                </a>
                <a
                  href="https://www.argentina.gob.ar/aaip/datospersonales"
                  target="_blank"
                >
                  <v-img
                    id="imageRegistroHomeDesktop"
                    src="@/assets/img/image_registro.png"
                    class="divHomeTopMargin"
                  />
                </a>
              </v-col>
            </v-row>
          </div>
          <div class="paddingFooterDesktop marginPorcentajesFooterDesktop">
            <p class="divHomeTopMargin whiteText">
              El período mínimo para la devolución de un préstamo es de 3 meses
              y el máximo es de 24 meses. Todos los préstamos son a tasa fija,
              en pesos y otorgados bajo el sistema de amortización francés con
              cuotas mensuales y consecutivas. La aprobación definitiva del
              préstamo está sujeta al cumplimiento de los requisitos solicitados
              por SIFT S.A. Ejemplo: Monto solicitado de ${{
                this.$tasas().EjemploMonto
              }}
              a {{ this.$tasas().EjemploPeriodo }} meses | TNA (sin IVA):
              {{ this.$tasas().EjemploTnaSinIva }}% - TEA (sin IVA):
              {{ this.$tasas().EjemploTeaSinIva }}% - CFTNA (con IVA):
              {{ this.$tasas().EjemploCftnaConIva }}% - CFTEA (con IVA):
              {{ this.$tasas().EjemploCfteaConIva }}%* | Cuota: ${{
                this.$tasas().EjemploCuota
              }}
              |Total a pagar: ${{ this.$tasas().EjemploTotalaPagar }}. Este
              ejemplo no constituye una obligación de SIFT S.A. de ofrecer dicha
              tasa ya que la Tasa Nominal Anual (TNA), la Tasa Efectiva Anual
              (TEA) y el Costo Financiero Total (CFT) de un préstamo varían
              dependiendo del perfil crediticio del solicitante. En todos los
              casos, la TNA, TEA y el CFT aplicable serán informados antes de la
              aceptación de la oferta de préstamo por parte del solicitante. La
              TNA mínima es de {{ this.$tasas().TnaSinIvaMin }}% y la máxima de
              {{ this.$tasas().TnaSinIvaMax }}%. A su vez, la TEA mínima es de
              {{ this.$tasas().TeaSinIvaMin }}% y la máxima de
              {{ this.$tasas().TeaSinIvaMax }}%. CFTEA con IVA: Mínimo:
              {{ this.$tasas().CfteaConIvaMin }}% - Máximo
              {{ this.$tasas().CfteaConIvaMax }}%
            </p>
            <h2 class="divHomeTopMargin whiteText boldMountedText">
              CFTEA(*): {{ this.$tasas().EjemploCfteaConIva }}% (*)IVA incluido
              <br />
              CFTNA: {{ this.$tasas().EjemploCftnaConIva }}% (con IVA). TNA
              {{ this.$tasas().EjemploTnaSinIva }}% (sin IVA). TEA
              {{ this.$tasas().EjemploTeaSinIva }}% (sin IVA).
            </h2>
          </div>
          <go-top
            bg-color="#00AEAE"
            fg-color="#FFFFFF"
            :max-width="200"
            :size="50"
            :bottom="20"
            :right="20"
          />
        </div>
      </v-main>
    </v-app>
    <v-dialog v-model="isSafari" persistent width="500">
      <v-card>
        <div align="center" style="height: 200px">
          <v-img
            class="imageCircleDesktopLoading"
            src="@/assets/img/image_circle.png"
            alt="logo"
            width="50"
          >
            <v-img
              class="imagePetPenDesktopLoading"
              src="@/assets/img/pet_glasses.png"
              alt="Mascota"
            />
          </v-img>
        </div>
        <div style="height: 100px; margin-top: 20px">
          <h4>
            Para una correcta visualización de nuestra web te recomendamos usar
            Google Chrome.<br />
            Proximamente optimizaremos la versión para Safari.
          </h4>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSendMail"
      persistent
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-toolbar dark color="#FFDD00">
          <v-btn class="text-right" icon dark @click="dialogSendMail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer />
        <div align="center" style="height: 200px">
          <v-img
            class="imageCircleDesktopLoading"
            src="@/assets/img/image_circle.png"
            alt="logo"
            width="50"
          >
            <v-img
              class="imagePetPenDesktopLoading"
              src="@/assets/img/pet_glasses.png"
              alt="Mascota"
            />
          </v-img>
        </div>
        <div style="height: 100px; margin-top: 20px">
          <h4>{{ dialogSendMailMessage }}</h4>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GoTop from "@inotom/vue-go-top";

export default {
  name: "App",
  components: { GoTop },
  data: function () {
    return {
      year: new Date().getFullYear(),
      title: "Endorfine",
      subscribed: false,
      available_amount: [],
      formDue: {
        amount: process.env.VUE_APP_DEFAULT_AMOUNT,
        installment: process.env.VUE_APP_DEFAULT_INSTALLMENT,
        avgInstallment: "",
        date: "",
        color: "#000000",
        trackColor: "#F0F0F0",
        loanId: null,
      },
      helpForm: {
        name: "",
        lastName: "",
        phone: "",
        email: "",
        comments: "",
      },
      panel: "",
      drawer: false,
      items: null,
      phoneWpp: null,
      facebook: null,
      instagram: null,
      alignWpp: null,
      justifyWpp: null,
      showOpinions: false,
      isSafari: false,
      dialogSendMail: false,
      dialogSendMailMessage: "",
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        counterCel: (value) =>
          value.length === 10 || "Este campo debe contener 10 caracteres.",
        validateMail: (v) =>
          /.+@.+\..+/.test(v) || "Este campo debe ser un e-mail válido.",
      },
      valid_documentation: true,
      minInstallment: parseInt(process.env.VUE_APP_MIN_INSTALLMENT),
      maxInstallment: parseInt(process.env.VUE_APP_MAX_INSTALLMENT),
      minAmount: process.env.VUE_APP_MIN_AMOUNT,
      maxAmount: process.env.VUE_APP_MAX_AMOUNT,
      homeUserFeature: "1" === process.env.VUE_APP_HOME_USER_FEATURE,
    };
  },
  watch: {
    created() {
      this.$store.commit("SET_DEVICE");
    },
  },
  mounted() {
    this.resetStore();
    this.phoneWpp = process.env.VUE_APP_PHONE_CUSTOMER_SUPPORT_FORMATTED;
    this.facebook = process.env.VUE_APP_PROFILE_FACEBOOK;
    this.instagram = process.env.VUE_APP_PROFILE_INSTAGRAM;
    if (this.$showSocialMedia()) {
      this.alignWpp = "left";
      this.justifyWpp = "left";
    } else {
      this.alignWpp = "center";
      this.justifyWpp = "center";
    }
    this.showOpinions = process.env.VUE_APP_SHOW_OPINIONS === "1";
    this.items = [
      { title: "Beneficios", id: "PorQuePrester" },
      /*{ title: "Cómo funciona", id: "ComoCredito" },*/
      { title: "Ayuda", id: "Ayudar" },
      { title: "Preguntas frecuentes", id: "PreguntasFrecuentes" },
    ];
    if (this.showOpinions) {
      this.items.splice(2, 0, {
        title: "Comunidad",
        id: "Opiniones",
      });
    }

    this.isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1;
  },
  methods: {
    ...mapActions(["resetStore", "openLoader", "closeLoader"]),
    subscribe: function () {
      this.subscribed = !this.subscribed;
    },
    startLoan() {
      this.resetStore();
      this.$store.commit("SET_LOAN", this.formDue);
      this.$gtm.trackEvent({
        noninteraction: false,
        event: "WEB_ARG_CREDIT_START",
      });
      this.$router.push({ name: "request_login" });
    },
    toSectionNavDrawer(reqId) {
      this.drawer = !this.drawer;
      window.scrollTo(0, $("#" + reqId).offset().top);
    },
    toSection(reqId) {
      window.scrollTo(0, $("#" + reqId).offset().top);
    },
    sendEmailForHelp() {
      this.dialogSendMailMessage = "Enviando tu consulta...";
      this.dialogSendMail = true;
      this.$apiserver
        .sendFormHelpData(this.helpForm)
        .then((data) => {
          this.dialogSendMailMessage =
            "Ya recibimos tu consulta! Nos comunicaremos a la brevedad.";
          this.dialogSendMail = true;
          this.resetHelpForm();
        })
        .catch((e) => {
          this.$store.commit("ERROR_MODAL", true);
          console.log(e);
          this.dialogSendMailMessage =
            "No pudimos enviar tu consulta. Intenta más tarde.";
        })
        .finally(() => {});
    },
    resetHelpForm() {
      this.$refs.formHelp.resetValidation();
      this.$refs.formHelp.reset();
    },
    loginUser() {
      this.$router.push({ name: "request_signin" });
    },
  },
};
</script>

<style scoped>
>>> .v-slider--horizontal .v-slider__track-container {
  height: 4px;
}

.v-expansion-panel-content {
  margin-top: 10px !important;
}
</style>