import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { initializeApp } from 'firebase/app';
import { config } from './helper/firebaseConfig'
import { gtmConfig } from './helper/gtmConfig'
import swal from 'sweetalert2';
window.Swal = swal;
import VuetifyMoney from "vuetify-money";
import money from 'v-money';
import JsonExcel from "vue-json-excel";
import ApiServer from "./plugins/api.service";
import Storage from 'vue-ls';
import * as VueSpinnersCss from "vue-spinners-css";
import VueGtm from '@gtm-support/vue2-gtm';
import { apiRoute } from "@/helper/apiRoute";
import tasas from "@/helper/tasas.json";
import loadingDialog from "@/components/popUps/LoadingDialog";
import { photoText } from "@/helper/photoText";
import { businessVars } from "./helper/businessVars";

require('./bootstrap');
const options = {
  namespace: 'solicitud__', // key prefix
  name: 'ls',
  storage: 'session', // storage name session, local, memory
};
Vue.use(Storage, options);
Vue.config.productionTip = false;
Vue.use(VuetifyMoney);
Vue.use(money, {precision: 4})
Vue.component("DownloadExcel", JsonExcel);
let minInstallment = process.env.VUE_APP_MIN_INSTALLMENT;
let maxInstallment = process.env.VUE_APP_MAX_INSTALLMENT;
Vue.prototype.$apiserver= new ApiServer(apiRoute, minInstallment, maxInstallment);
Vue.use(VueSpinnersCss);
Vue.component('LoadingDialog', loadingDialog);
store.commit('SET_DEVICE');
let device = store.getters.getIsDevice?'CELLPHONE':'DESKTOP';
Vue.prototype.$docText = photoText[device];
Vue.prototype.$business = businessVars;
const AuxFunctions = {
  install(Vue, options) {
    Vue.prototype.isNumber = (evt) => {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
  },
}
Vue.use(AuxFunctions)

window.axios.interceptors.request.use(
    config => {
      let isLogin = Vue.prototype.$apiserver.isLogin();
      if (!isLogin && config.url !== apiRoute.SINGUP && config.url !== apiRoute.SEND_QUERIES) {
        throw new Error("invalid_login");
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
);

Vue.use(VueGtm, gtmConfig);

Vue.prototype.$screenIsSmall =function() {
  let isSmall=false;
  if(screen.width < 960)
  isSmall = true;
  return isSmall;
};

Vue.prototype.$tasas = function(){

  return tasas;

};

Vue.prototype.$showSocialMedia = function(){

  return process.env.VUE_APP_SHOW_SOCIAL_MEDIA === "1";
}

const firebaseApp = initializeApp(config);


const app = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  vuetify,
  i18n,
  firebaseApp,
  mounted: function() {
    Vue.ls.set('foo', 'boo', 1 * 1 * 1000); //expiry 1 hour
    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
    }
    Vue.ls.on('foo', callback) //watch change foo key and triggered callback
  }
});
