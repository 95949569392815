<template>
  <v-card class="cardDialog" style="height: 468px !important">
    <v-container style="background-color: #ffffff">
      <v-row align="center" justify="center">
        <h3 class="textHome" style="margin-top: 30px">Hola!</h3>
      </v-row>
      <v-row>
        <v-col align="center" justify="center">
          <v-img
            class="imagePetPenDesktopLoading"
            src="@/assets/img/pet_sad.svg"
            alt="Mascota"
          />
        </v-col>
      </v-row>

      <v-row class="headerRoundedWhiteAmarillo"> &nbsp; </v-row>
      <v-row align="center" class="bodyAmarillo">
        <v-col align="center" justify="center">
          <p>{{ messageInformation }}</p>
        </v-col>
      </v-row>
      <v-row align="center" class="bodyAmarillo">
        <v-col align="center" justify="center">
          <v-btn
            bottom
            depressed
            rounded
            class="btn-default"
            @click="actionBtn()"
          >
            Continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "InformationModal",
  data: () => ({
    messageInformation: null,
  }),
  computed: {
    actionBtn() {
      return this.$store.getters.getInformationAction;
    },
  },
  mounted() {
    this.messageInformation = this.$store.getters.getInformationMessage;
  },
};
</script>

<style scoped>
</style>